import React from "react";
import {Link} from 'gatsby';
import Layout from '../components/layout';
import {Container, Col, Row} from 'reactstrap';
import './style/solt-room.css';
import Quiz from '../components/quiz/quiz';
import TextToggle from '../components/text-toggle/text-toggle';
import Picking from '../components/picking/picking';
import GalleryInstagram from '../components/gallery-instagram/gallery-instagram';
import Stalactite from '../images/svg-images/stalactite-up-product.svg';
import Stalagmite from '../images/svg-images/stalagmite-up-product.svg';
import BgSoltRoom from '../images/solt-cave-israil.jpg';
import BgSoltCave from '../images/solt-room-white.jpg';


const SoltRooms = (props) => {

    return (
        <>
        <Layout>
            <div className="position-relative  p-3 p-md-5 mt-md-3 text-center">
                <div className="col-md-5 p-lg-5 mx-auto my-5">
                    <h1 className="display-4 font-weight-normal">Солевые комнаты</h1>
                    <p className="lead font-weight-normal">Строительство соляных пещер (комнат) по России и СНГ </p>
                    <Quiz></Quiz>
                </div>
                <div className="product-device shadow-sm d-none d-md-block title-h1">
                    <object type="image/svg+xml" data={Stalagmite} aria-label="SVG element sali cave"/>
                </div>
                <div className="product-device product-device-2 shadow-sm d-none d-md-block">
                    <object type="image/svg+xml" data={Stalactite} aria-label="SVG element sali cave"/>
                </div>
                </div>

                <Row className="d-md-flex flex-md-equal w-100 pl-md-3">
                <Col className="pb-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white ">
                    <div className="my-3 py-3">
                        <h2 className="display-5">Соляная комната в частном доме или квартире</h2>
                        <p className="lead pb-3">Собственная солевая пещера в вашем доме или квартире.</p>
                        <Link to="/solyanye-peschery/domashnyaya-solyanaya-komnata"  className="button-solt mt-3 m-2 font_white">УЗНАТЬ БОЛЬШЕ</Link>
                    </div>
                    
                </Col>
                <Col style={{backgroundImage:`url(${BgSoltRoom})`}} className="bg-product pb-3 pt-3 px-3 pt-md-5 px-md-5 text-center ">
                        <div style={{height: "100%", background: "#ffffff73", borderRadius: "20px"}}>
                            <div className="my-3 p-3">
                                <h2 className="display-5">Соляная комната для SPA</h2>
                                <p className="lead pb-3">Откройте свой бизнес и оказывайте больше услуг для своих клиентов.</p>
                                <Link to="/solyanye-peschery/solyanaya-peschera-dlya-pansionatov"  className="button-solt mt-3 m-2 font_white">УЗНАТЬ БОЛЬШЕ</Link>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
                <Col  style={{backgroundImage:`url(${BgSoltCave})`}} className=" bg-product pb-3 pt-3 px-3 pt-md-5 px-md-5 text-center ">
                    <div style={{height: "100%", background: "#002b6b82", borderRadius: "20px"}}>   
                        <div className="my-3 p-3">
                            <h2 className="font-white display-5">Для пансионатов и санаториев</h2>
                            <p className="lead font-white pb-3">Привликайте больше постояльцев и проводите болеекачественное лечение.</p>
                            <Link to="/solyanye-peschery/solyanaya-peschera-dlya-pansionatov"  className="button-solt mt-3 m-2 font_white">УЗНАТЬ БОЛЬШЕ</Link>
                        </div>
                        
                    </div>
                </Col>
                <Col className="pb-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white ">
                    <div className="my-3 py-3">
                        <h2 className="display-5">Образовательные учреждения</h2>
                        <p className="lead pb-3">В здоровом теле здоровый ум.</p>
                        <Link to="/solyanye-peschery/solyanye-komnaty-dlya-shkol"className="button-solt mt-3 m- font_white">УЗНАТЬ БОЛЬШЕ</Link>
                    </div>

                </Col>
                </Row>
                <Container>
                    <Row>
                    <h1 className="h2 text-center blue-color mt-3">Строительсьво солевых пещер и комнат в России </h1>
                    <span className="separator-fullScreen"></span>
                    <Col md="12" className="text-justify">
                        <p>
                        Компания RoyalGroup выполняет работы по строительству соляных пещер (комнат) с выездом
                        специалистов по всей территории России и СНГ, а также поставкой галогенераторов и другого
                        необходимого для функционирования соляной пещеры оборудования.
                        </p>
                        <TextToggle>
                            <p>
                            Наша компания предлагает несколько пакетов услуг в разных ценовых категориях,
                            благодаря чему Вы сами можете выбрать, какую соляную пещеру построить.
                            Наши работы отличает великолепный дизайн и безупречное качество исполнения.
                            Бесплатная консультация специалиста поможет определиться с выбором оборудования для соляной пещеры
                            которое Вы можете приобрести у нас без дополнительных наценок по цене производителя.
                            </p>
                        </TextToggle>
                        
                        
                        </Col>
                    </Row>
                    <hr className="my-3" /> 
                </Container>
                <Picking></Picking>
                <GalleryInstagram />
            </Layout>
        </>
    )
}

export default SoltRooms;