import React, {Component} from 'react';
//import {Link} from "gatsby"; 
import {Container, Row, Col} from 'reactstrap';

import MaterialsButton from './buttonGroup/materialButtons';
import TypeRoomeButtons from './buttonGroup/typeRoomeButtons';
import FloorButtons from './buttonGroup/floorButtons';
import FormSubmit from './form/form-submit';
import "./quiz.css"



export default class Quiz extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: {
            purpose: "",
            dуsign: "",
            typeOfSalt: "",
            floor: "",
            area: "10",
            name: "",
            phone: "",
            email: "",
            respServer: {
                success: undefined,
                error: ''
              }},
            formVisibility: false,
            buttonsPurpose: [
                {id:1,title: "Для бизнеса", isChoise:true},
                {id:2,title: "Закупки ФЗ-44 и ФЗ-223", isChoise:false},
                {id:3,title: "Для себя", isChoise:false}
            ],
            buttonsMaterial:[
                {id:1, title:"Белая Илецкая соль", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/1.jpg", isChoise:true},
                {id:2, title:"Розовая Гималайская соль", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/2.jpg", isChoise:false},
                {id:3, title:"С элементами белой и Гималайской соли", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/3.jpg", isChoise:false},
            ],
            buttonTypeRoom: [
                {id:1, title:"Соляную комнату", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/2_1.jpg", isChoise:true},
                {id:2, title:"Грот", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/2_2.jpg", isChoise:false},
                {id:3, title:"С элементами соляной комнаты и грота", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/2_3.jpg", isChoise:false},
            ],
            buttonFloor: [
                {id:1, title:"Белая Илецкая соль", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/3_1.jpg", isChoise:true},
                {id:2, title:"Розовая Гималайская соль", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/3_2.jpg", isChoise:false},
                {id:3, title:"Любой материал", img: "http://royalgroup.msk.ru/wp-content/uploads/2020/06/3_3.jpg", isChoise:false},
            ],        
        }
        this.quizShow = this.quizShow.bind(this);
        this.quizClose = this.quizClose.bind(this);
        this.choisePurpose = this.choisePurpose.bind(this);
        this.choiseMaterial = this.choiseMaterial.bind(this);
        this.choiseTypeRoom = this.choiseTypeRoom.bind(this);
        this.choiseFloor = this.choiseFloor.bind(this);
        this.choiseArea = this.choiseArea.bind(this);
        this.setName = this.setName.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    quizShow() {
        this.setState({formVisibility: !this.state.formVisibility})
    }
    quizClose() {
        this.setState({formVisibility: !this.state.formVisibility})
    }

    choisePurpose(id) {
       this.setState(({buttonsPurpose}) => {
        const indexChoiseTrue = buttonsPurpose.findIndex(elem=>elem.isChoise===true);
        const oldElemChoise = buttonsPurpose[indexChoiseTrue];
        const newElemChoise = {...oldElemChoise, isChoise: !oldElemChoise.isChoise};
        const newList = [...buttonsPurpose.slice(0, indexChoiseTrue), newElemChoise, ...buttonsPurpose.slice(indexChoiseTrue + 1)];
        const index = newList.findIndex(elem=>elem.id===id)
        let oldElem = newList[index]
        oldElem.isChoise = !oldElem.isChoise
        return {buttonsPurpose: [...newList.slice(0, index), oldElem, ...newList.slice(index + 1)]}
       })
    }

    choiseMaterial(id) {
        this.setState(({buttonsMaterial}) => {
            const indexChoiseTrue = buttonsMaterial.findIndex(elem=>elem.isChoise===true);
            const oldElemChoise = buttonsMaterial[indexChoiseTrue];
            const newElemChoise = {...oldElemChoise, isChoise: !oldElemChoise.isChoise}
            const newList = [...buttonsMaterial.slice(0, indexChoiseTrue), newElemChoise, ...buttonsMaterial.slice(indexChoiseTrue + 1)];
            const index = newList.findIndex(elem => elem.id === id);
            let old = newList[index];
            old.isChoise = !old.isChoise
            return {
                buttonsMaterial: [...newList.slice(0, index), old, ...newList.slice(index + 1)]
            }
        })
    }

    choiseTypeRoom(id) { 
        this.setState(({buttonTypeRoom}) => {
            const indexChoiseTrue = buttonTypeRoom.findIndex(elem=>elem.isChoise===true);
            const oldElemChoise = buttonTypeRoom[indexChoiseTrue];
            const newElemChoise = {...oldElemChoise, isChoise: !oldElemChoise.isChoise};
            const newList = [...buttonTypeRoom.slice(0, indexChoiseTrue), 
                newElemChoise, ...buttonTypeRoom.slice(indexChoiseTrue + 1)];
            const index = newList.findIndex(elem => elem.id === id);
            let oldElem = newList[index];
            oldElem.isChoise= !oldElem.isChoise
            return {buttonTypeRoom: [...newList.slice(0,index), oldElem, ...newList.slice(index + 1)]}
        })
    }


 choiseFloor(id) {
        this.setState(({buttonFloor}) => {
            const indexChoiseTrue = buttonFloor.findIndex(elem=>elem.isChoise===true);
            const oldElemChoise = buttonFloor[indexChoiseTrue];
            const newElemChoise = {...oldElemChoise, isChoise: !oldElemChoise.isChoise};
            const newList = [...buttonFloor.slice(0, indexChoiseTrue), newElemChoise, ...buttonFloor.slice(indexChoiseTrue + 1)];
            const index = newList.findIndex(elem=>elem.id===id)
            let oldElem = newList[index]
            oldElem.isChoise = !oldElem.isChoise
            return {buttonFloor: [...newList.slice(0, index), oldElem, ...newList.slice(index + 1)]}

        })
    }
    choiseArea(event) {
        const {value} = event.target;
        this.setState(prevState => ({
           data: {
               ...prevState.data,
               area: value
           }
        }))
    }
    setName(e) {
        const{value}= e.target
        this.setState(prevState => ({
            data:{
            ...prevState.data,
            name: value}
        }))

    }
    setPhone(e) {
        const {value} = e.target;
        this.setState(prevState => ({
            data:{
            ...prevState.data,
            phone: value}
        }))
    }
    setEmail(e) {
        const {value} = e.target;
        this.setState(prevState => ({
            data:{
            ...prevState.data,
        email: value}
        }))

    }

    formSubmit(event) {
        event.preventDefault();
        const {buttonsPurpose, buttonsMaterial, buttonTypeRoom, buttonFloor} = this.state;
        this.setState(prevState => ({
            data:{
            ...prevState.data,
            purpose: buttonsPurpose[buttonsPurpose.findIndex(elem => elem.isChoise === true)],
            dуsign: buttonsMaterial[buttonsMaterial.findIndex(elem => elem.isChoise === true)],
            typeOfSalt: buttonTypeRoom[buttonTypeRoom.findIndex(elem => elem.isChoise === true)],
            floor: buttonFloor[buttonFloor.findIndex(elem => elem.isChoise === true)]}
        }))
        fetch("https://www.inlingua39.ru/php/sendmail.php", {
          method:"POST",
          body: JSON.stringify(this.state.data),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json"
          })
        }).then(response => {
          response.json().then(data => {
            this.setState({respServer: data, 
              isFetching: false})
          })
        })
      }

    render() {
        const {formVisibility,
            buttonsPurpose,
            buttonsMaterial,
            buttonFloor,
            buttonTypeRoom,} = this.state;
        const {name, phone, email, respServer} = this.state.data;

        const materialImg = buttonsMaterial[buttonsMaterial.findIndex(elem=>elem.isChoise===true)].img;
        const typeRoomImage = buttonTypeRoom[buttonTypeRoom.findIndex(elem=>elem.isChoise===true)].img;
        const typeFloorImage = buttonFloor[buttonFloor.findIndex(elem=>elem.isChoise===true)].img;
        
        const choisePurposeButton = buttonsPurpose.map(item => {
            const {...itemProps} = item;
            return (
                <MaterialsButton key={itemProps.id} choiseMaterial={this.choisePurpose} {...itemProps}></MaterialsButton>
            )
            
        })    
        const choiseMaterialBattons = buttonsMaterial.map(item => {
            const {...itemProps} = item;
            return(
            <MaterialsButton key={itemProps.id} choiseMaterial={this.choiseMaterial} {...itemProps}></MaterialsButton>
            )
        })
        const choiseTypeRoomButtons = buttonTypeRoom.map( item => {
            const {...itemProps} = item;
            return(
                <TypeRoomeButtons key={itemProps.id} choiseTypeRoom={this.choiseTypeRoom}  {...itemProps}></TypeRoomeButtons>
            )
        })

        const choiseFloorButton = buttonFloor.map( item => {
            const {...itemProps} = item;
            return(
                <FloorButtons key={itemProps.id} choiseFloor={this.choiseFloor}  {...itemProps}></FloorButtons>
            )
        })

        return (
            <>
                <button className="button-solt font_white" onClick={this.quizShow}>УЗНАТЬ СТОИМОСТЬ</button>

                <div className={!formVisibility?"d-none":"d-block popUp-quiz"}>
                   <Container className="popUp">
                       <button className="close-btn" onClick={this.quizClose}>
                           X
                       </button>
                       <Row className="mt-3 mb-2">
                           <Col>
                           <h3>Пройди тест до конца, узнай стоимость своего проекта и получи подарок</h3>
                           </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                        <Col md="12"><h4 className="mt-4 mb-3 h4">Выберите назначение:</h4></Col>
                        <Col md="12">
                            {choisePurposeButton}
                        </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                        <Col md="12"><h4 className="mt-4 mb-3 h4">Какой солью будет офрмлена ваша комната?</h4></Col>
                        <Col md="6">
                            {choiseMaterialBattons}                       
                        </Col>
                        <Col md="6">
                            <div>
                                <img className="previewImage p-2" src={materialImg} alt="" title="" />
                            </div>
                        </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                        <Col md="12"><h4 className="mt-4 mb-3 h4">Что хотите построить?</h4></Col>
                           <Col md="6">
                            {choiseTypeRoomButtons}
                           </Col>
                           <Col md="6">
                               <div>
                                   <img className="previewImage p-2" src={typeRoomImage} alt="" title="" />
                               </div>
                           </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                       <Col md="12"><h4 className="mt-4 mb-3 h4">Из какой соли будет выполнен пол в вашей комнате?</h4></Col>
                       <Col md="6">
                           {choiseFloorButton}
                           </Col>
                           <Col md="6">
                               <div>
                                   <img className="previewImage p-2" src={typeFloorImage} alt="" title="" />
                               </div>
                           </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                       <Col md="12"><h4 className="mt-4 mb-3 h4">Площадь вашего помещения?</h4></Col>
                       <Col md="12">
                            <div className="w-100 text-center">
                                <p>{this.state.data.area} м<sup><small>2</small></sup></p>
                            </div> 
                        <input type="range" onChange={this.choiseArea} className="mt-2 mb-5 custom-range" min="0" max="100" step="5" value={this.state.data.area} id="customRange3" />
                       </Col>
                       </Row>
                       <Row className="mt-5 mb-2">
                       <Col md="12">
                           <h3>Оставьте свои контактные данные</h3>
                           <h5>и вместе с расчетом вы получите каталог готовых соляных комнат в подарок!</h5>
                        </Col>
                            <FormSubmit setName={this.setName}
                                         setPhone={this.setPhone}
                                         setEmail={this.setEmail}
                                         name={name}
                                         phone={phone}
                                         email={email}
                                         formSubmit={this.formSubmit}
                                         respServer={respServer}/>                                                                           
                       </Row>                           
                    </Container> 
                </div>
            </>
        )
    }
}