import React, {Component} from 'react';

export default class MaterialButtons extends Component{
    constructor(props){
        super(props);
    }

    render() {
        const {title, id, isChoise, choiseTypeRoom} = this.props;
        let style = "button-outline m-2";
        if(isChoise) {
            style = "button-solt m-2";
        } 
        return (
            <button onClick={() => choiseTypeRoom(id)} className={style}>{title}</button>
        )
    }
}