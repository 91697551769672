import React, {Component} from 'react';
import {Col} from 'reactstrap';


export default class FormSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }
       
    }


    render() {
        const {setEmail, setPhone, setName, name, phone, email, formSubmit, respServer} = this.props;
        let textOnButton = 'Узнать стоимость'
        let btnDisable = false;
        if (!!respServer) {
          if (respServer.success === true) {
            textOnButton = 'Заявка принята!';
            btnDisable = true;
          } else if (respServer.error !== '') {
            textOnButton = 'Что то пошло не так!';
          }
        }
    return (
        <form 
        className="w-100 mt-3 mb-5"
        onSubmit={formSubmit}>
            <div className="d-flex mt-3 mb-3">
            <Col md="4">
             <input
                type="text"
                placeholder="Ваше имя"
                className="form-control new-post-label form-input"
                onChange={setName}
                value={name}
                />
             </Col>
             <Col md="4">
             <input
                type="text"
                placeholder="Ваш телефон"
                className="form-control new-post-label form-input"
                onChange={setPhone}
                value={phone}
                />
             </Col>
             <Col md="4">
             <input
                type="text"
                placeholder="Ваша почта"
                className="form-control new-post-label form-input"
                onChange={setEmail}
                value={email}
                />
             </Col>
            </div>
    
           <Col md="12">
           <button disabled={btnDisable}
                type="submit"
                className="button-solt m-2">
                    {textOnButton}
             </button>
           </Col>

        </form>       

    )
}
}
