import React, {Component} from 'react';
//import {Link} from "gatsby"; 
import {Container, Row, Col} from 'reactstrap';
import "./popup-form.css"



export default class PopupForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: {
            purpose: "",
            dуsign: "",
            typeOfSalt: "",
            floor: "",
            area: "10",
            name: "",
            phone: "",
            email: "",
            respServer: {
                success: undefined,
                error: ''
              }},
            formVisibility: false,       
        }
        this.quizShow = this.quizShow.bind(this);
        this.quizClose = this.quizClose.bind(this);
        this.setName = this.setName.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }

    quizShow() {
        this.setState({formVisibility: !this.state.formVisibility})
    }
    quizClose() {
        this.setState({formVisibility: !this.state.formVisibility})
    }

    setName(e) {
        const{value}= e.target
        this.setState(prevState => ({
            data:{
            ...prevState.data,
            name: value}
        }))

    }
    setPhone(e) {
        const {value} = e.target;
        this.setState(prevState => ({
            data:{
            ...prevState.data,
            phone: value}
        }))
    }
    setEmail(e) {
        const {value} = e.target;
        this.setState(prevState => ({
            data:{
            ...prevState.data,
        email: value}
        }))

    }

    formSubmit(event) {
        event.preventDefault();
        fetch("https://www.inlingua39.ru/php/sendmail.php", {
          method:"POST",
          body: JSON.stringify(this.state.data),
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json"
          })
        }).then(response => {
          response.json().then(data => {
            this.setState({respServer: data, 
              isFetching: false})
          })
        })
      }

    render() {
        const {prodTitle, prodImage, prodPrice} = this.props;
        const {formVisibility,} = this.state;
        const {name, phone, email, respServer} = this.state.data;
        let textOnButton = 'Оставить заявку'
        let btnDisable = false;
        console.log(respServer.success)
        if (!!respServer) {
          if (respServer.success === true) {
            textOnButton = 'Заявка принята!';
            btnDisable = true;
          } else if (respServer.error !== '') {
            textOnButton = 'Что то пошло не так!';
          }
        }

        return (
            <>
                <button className="picking-btn w-75 mt-2 mb-4" onClick={this.quizShow}>ЗАКАЗАТЬ</button>

                <div className={!formVisibility?"d-none":"d-block popup-section-order"}>
                   <Container className="popup-order">
                       <div className="close-btn font-white" onClick={this.quizClose}>
                           X
                       </div>
                       <Row>
                           <Col md="4">
                               <h4>{prodTitle}</h4>
                               <p className="font-white">цена: <span className="new-price">{prodPrice}</span></p>
                           </Col>
                           <Col md="8">
                               <form 
                                className="w-100 mt-3 mb-5"
                                onSubmit={this.formSubmit}>
                                    <h4>Оставьте заявку на услугу</h4>
                                    <p className="popup-title font-white">Наш специалит свяжется с вами для консультации об услугах</p>
                                    <Row className="d-flex mt-3 mb-3">
                                    <Col md="12" xs="12" className="mb-2">
                                    <input
                                        type="text"
                                        placeholder="Ваше имя"
                                        className="form-control new-post-label popup-form-input"
                                        onChange={this.setName}
                                        value={name}
                                        />
                                    </Col>
                                    <Col md="12" xs="12" className="mb-2">
                                    <input
                                        type="text"
                                        placeholder="Ваш телефон"
                                        className="form-control new-post-label popup-form-input" 
                                        onChange={this.setPhone}
                                        value={phone}
                                        />
                                    </Col>
                                    </Row>
                            
                                <Col md="12">
                                <button disabled={btnDisable}
                                        type="submit"
                                        className="button-solt m-2">
                                            {textOnButton}
                                    </button>
                                </Col>
                                </form> 
                           </Col>
                        
                       </Row>
                                         
                    </Container> 
                </div>
            </>
        )
    }
}