import React,{Component} from 'react';
import {Container, Row, Col} from 'reactstrap';
import TextToggle from '../text-toggle/text-toggle';
import './picking.css';
import PopupForm from '../popup-form/popup-form';
import Slifovca from '../../images/shlifovca.jpg'; 
import ShlifovcaAbrozivom from '../../images/shlifovka-abrozivom.jpg';
import TepliyShov from '../../images/tepliy_shov.jpg';
import PokraskaSruba from '../../images/vostanovlenie-sruba-3.jpg'

const FliterButtonDesctop = (props) => {
    const {name, ischoise, filter, onUpdateActualFilter} = props;
    let btnStyle = 'btn d-flex flex-row tab-step-button';
    if(ischoise) {
        btnStyle = 'btn d-flex flex-row tab-step-button active-btn'
    } 
    return(
        <div className={btnStyle} onClick={() => onUpdateActualFilter(filter)}>{name}</div>
    )
}




export default class Picking extends Component {

    state = {
        actualFilter: "all",
        filters: [
            {name:'ОТБОР', filter:'selection', ischoise: true},
            {name:' ПЛЕМЕННОЙ СЕРТИФИКАТ', filter:'certificate', ischoise: false},
            {name:'КАРАНТИН И АНАЛИЗЫ', filter:'health', ischoise: false},
            {name:'КАРАНТИН', filter:'quarantine', ischoise: false},
            
        ],
        tabsContent: [
            {type:'selection', title:'ОТБОР', description:'Животные тщательно отбираются клиентом либо на фермах, где животные родились и выросли, либо на сборных площадках, при этом мы осуществляем предварительный  отбор согласно требованиям клиента.'},
            {type:'certificate', title:' ПЛЕМЕННОЙ СЕРТИФИКАТ', description:'Племенная регистрация: Все животные зарегистрированы в официальной племенной книге и сопровождаются племенным сертификатом, который показывает как минимум последние 3 поколения предков.'},
            {type:'health', title:'КАРАНТИН И АНАЛИЗЫ', description:'В зависимости от требований на импорт наша компания проводит необходимые карантинные мероприятия и требуемые анализы, вакцинации и обработки. Наш карантинный центр находится в Калининграде.'},
            {type:'quarantine', title:'КАРАНТИН', description:'Обычно срок карантинирования в стране происхождения составляет от 21 до 30 дней. В течение этого времени все животные находятся под наблюдением государственного ветеринарного врача страны происхождения.'},
        ]
    }
    

    onUpdateActualFilter = (actual) => {
        const {filters, deviceType} = this.state;

        const indexOfSelected = filters.findIndex(elem => {
            return elem.ischoise === true;
        })
        const newElem = filters[indexOfSelected]
        newElem.ischoise = false;
        const newState = [...filters.slice(0, indexOfSelected), newElem, ...filters.slice(indexOfSelected + 1, filters.length)]
        console.log(newState)
        console.log('Actual', actual)

        const indexOfSelect = filters.findIndex(elem => {
            return elem.filter === actual;
        })
        const selectedElement = newState[indexOfSelect];
        console.log(selectedElement)
        selectedElement.ischoise = true;
        const newfiltersState = [...newState.slice(0, indexOfSelect), selectedElement, ...newState.slice(indexOfSelect + 1, newState.length)];
        this.setState({
            filters: newfiltersState,
        })
    }

    render() {
        const {filters, tabsContent} = this.state;

        const visibleFilters = filters.map((elem, i) => {
            const {name, filter, ischoise} = elem;
            return (
                <FliterButtonDesctop key={i} name={name} ischoise={ischoise} filter={filter} onUpdateActualFilter={this.onUpdateActualFilter} />
            )
        })

        const visibleContent = tabsContent.map((elem, i) => {
            const {type, title, description} = elem;
          if(type === filters[filters.findIndex(elem => elem.ischoise === true)].filter) {
            return (
                <div key={i}>
                    <h5 className="font-green-light">{title}</h5>
                    <p >{description}</p>
                </div>
                )
          }
        })
        return (
            <Container fluid className=" cards-list" id="how-ordered">
                <Row className="mt-5 pt-3">
                    <Col  xs="12" className="text-center">
                        <div className="text-center mb-3">
                            <h2 className="h4">Как заказать или купить коз и овец?</h2>
                            <h4 className="steps-text"></h4>
                        </div> 
                    </Col>
                    <Col xs="12" className="text-center mb-5 mt-5">
                        <Row>
                            <Col sm="6" md="3" className="text-center step-container">
                                <p className="step-border-right">
                                    <span className="steps-num">1</span>
                                </p>
                                <span className="separator-steps d-block d-sm-none"></span>
                                <h5 className="text-uppercase mt-3">Заключаем контракт</h5>
                            </Col>
                            <Col sm="6" md="3" className="text-center step-container">
                                <p className="step-border-right">
                                    <span className="steps-num">2</span>
                                </p>
                                <span className="separator-steps d-block d-sm-none"></span>
                                <h5 className="text-uppercase mt-3">Выбираете животных</h5>
                            </Col>
                            <Col sm="6" md="3" className="text-center step-container">
                                <p className="step-border-right">
                                    <span className="steps-num">3</span>
                                </p>
                                <span className="separator-steps d-block d-sm-none"></span>
                                <h5 className="text-uppercase mt-3">Доставка и карантин</h5>
                            </Col >
                            <Col sm="6" md="3" className="text-center step-container">
                                <p>
                                    <span className="steps-num">4</span>
                                </p>
                                <span className="separator-steps d-block d-sm-none"></span>
                                <h5 className="text-uppercase mt-3">Забираете животных </h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="12" >
                        <div className="text-center mt-3">
                            <h4 className="steps-text">Мы будем рады помочь вам на протяжении всего процесса, обеспечивая тем самым всестороннюю коммерческую поддержку</h4>
                        </div>
                    </Col>
                </Row>
                <span className="separator-mb-none mb-none d-none d-md-block"></span>
                <Container fluid className="tubs-contaner">
                    <Container className="mt-md-0 mt-5"> 
                        <Row className="pb-3">
                            <Col md="4">
                                <div>
                                    {visibleFilters}
                                </div>
                            </Col>
                            <Col md="8" className="content-col p-2 text-justify mt-5 mt-md-0">{visibleContent}</Col>
                        </Row>
                    </Container>
                </Container>
            </Container>
        )
    }
}




